.center {
	margin: 0 auto;
	width: 1000px;

	
}

.center-inner {
	margin: 0 auto;
	width: 730px;
}

.flex {
	display: flex;
	gap: 10px;
}

.space-between {
	justify-content: space-between;
}

.space-evenly {
	justify-content: space-evenly;
}

.space-around {
	justify-content: space-around;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.align-center {
	align-items: center;
}

.text-center {
	text-align: center;
}



html {
	scroll-behavior: smooth;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
	font-family: 'Montserrat', sans-serif;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

* {
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

a {
	text-decoration: none;
	color: inherit;
}

.swal-button {
	padding: 7px 19px;
	border-radius: 2px;
	background-color: #538db4;
	font-size: 12px;
	border: 1px solid #538db4;
	text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
	transition: 0.5s all;
}

.swal-button:hover{
	background-color: #538db4;
	transform: scale(1.08);
}

.swal-button:not([disabled]):hover {
	background-color: #538db4;
	transform: scale(1.08);
}

.swal-button-container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 768px) {
	.center, .center-inner{
		width: 100%;
		padding: 0px 20px;
	}
	
	.space-around{
		justify-self: center;
	}

	.flex{
		flex-direction: column;
	}
}